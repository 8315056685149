const preloadImage = (src) =>
  new Promise((r) => {
    const image = new Image();
    image.onload = r;
    image.onerror = r;
    image.src = src;
  });

const links = document.querySelectorAll(".c-work__title");

links.forEach((link) => {
  preloadImage(link.dataset.image);
  link.addEventListener("mouseenter", (e) => {
    const imageUrl = e.target.dataset.image;

    const row = e.target.parentElement.parentElement;

    const rowImage = row.querySelector(".c-row__image");

    rowImage.style.backgroundImage = `url(${imageUrl})`;

    rowImage.classList.add("c-row__image--active");
  });

  link.addEventListener("mouseleave", (e) => {
    const row = e.target.parentElement.parentElement;

    const rowImage = row.querySelector(".c-row__image");

    rowImage.classList.remove("c-row__image--active");
  });
});
